import React from "react";
import { Image } from "react-bootstrap";
import ImageFoodGroup from "./foodGroup/ImageFoodGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { getfooditemsbyfoodgroupID } from "../../api/Api";
import * as allergensIcons from "../../styles/allergens";

class FoodGroup_Land extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type_size: this.props.item.typeSize,
      foodgroup: [],
    };
  }

  //get MAP FROM DISPLAY ID

  componentDidMount() {
    this.getFoodItems();
    this.interval = setInterval(this.getFoodItems.bind(this), 180 * 10000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  getFoodItems = () => {
    getfooditemsbyfoodgroupID(this.props.item.id).then((res) => {
      if (res == null) {
        this.setState({ foodgroup: [] });
      } else if (res[0]) {
        this.setState({ foodgroup: res });
      } else {
        this.setState({ foodgroup: [] });
      }
    });
  };

  //get Icon Allergens
  getIconSvg(allergen) {
    let allergenIcon = allergen
      .split(" ")
      .join("")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (allergen) {
      const svgIndex = "Svg" + allergenIcon;
      return allergensIcons[svgIndex];
    }
    return null;
  }

  //get WIDTH FLEX
  getwidthItems() {
    let activeFoodItem = this.state.foodgroup.filter(function (value) {
      return value.active !== false;
    }).length;

    let flex = " 25%";
    if (this.state.type_size === 1) {
      if (activeFoodItem > 6) {
        flex = " 20%";
      } else if (activeFoodItem <= 4) {
        flex = " 40%";
      } else if (activeFoodItem <= 6) {
        flex = " 30%";
      }
    } else if (this.state.type_size === 2) {
      if (activeFoodItem > 2) {
        flex = " 40%";
      } else if (activeFoodItem <= 2) {
        flex = " 55%";
      }
    } else if (this.state.type_size === 3) {
      flex = " 95%";
    } else if (this.state.type_size === 4) {
      flex = " 40%";
    } else if (this.state.type_size === 5) {
      flex = " 10%";
    }
    return flex;
  }

  //get max number of Food item
  getmaxFoodItem() {
    let slice = 8;
    if (this.state.type_size === 1) {
      slice = 8;
    } else if (this.state.type_size === 2) {
      slice = 4;
    } else if (this.state.type_size === 3) {
      slice = 2;
    } else if (this.state.type_size === 4) {
      slice = 2;
    } else if (this.state.type_size === 5) {
      slice = 4;
    }

    return slice;
  }
  getHeightItems() {
    let activeFoodItem =
      this.state.foodgroup.filter(function (value) {
        return value.active !== false;
      }).length;

    if (this.state.type_size === 1 && activeFoodItem <= 2) {
      return "85%"
    }
    else { return "400px" }
  }

  getMaxWidthItems() {
    let activeFoodItem =
      this.state.foodgroup.filter(function (value) {
        return value.active !== false;
      }).length;

    if (this.state.type_size === 1) {
      if (activeFoodItem === 3) {
        return " calc(50% - 60px)";
      } else {
        return " auto";
      }
    }
  }
  //Capitalize
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  render() {
    const divStyle = {
      height: "100%",
      width: "100%",
      transition: "all 250ms ease",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    const containerStyle = {
      padding: "10px",
      backgroundColor: this.props.item.background,
      height: "100%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
    };

    const foodItemStyle = {
      position: "relative",
      height: this.getHeightItems(),
      backgroundColor: "#FFFFFF",
      padding: "0",
      margin: "auto 30px",
      flex: "1 1" + this.getwidthItems(),
      maxWidth: this.getMaxWidthItems(),
    };

    const originStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamilySecondary,
      color: this.props.item.colorAccent,
      fontSize: this.props.item.fontSizeOrigin + "px",
    };
    const titleStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeTitle + "px",
    };

    const titleNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeTitleNi + "px",
    };
    const titleSecStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeTitleSec + "px",
    };
    const titleSecNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeTitleSecNi + "px",
    };

    const descriptionStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeDescription + "px",
    };

    const descriptionNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeDescriptionNi + "px",
    };

    const descriptionSecStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeDescriptionSec + "px",
    };
    const descriptionSecNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.props.item.fontFamily,
      color: this.props.item.colorPrimary,
      fontSize: this.props.item.fontSizeDescriptionSecNi + "px",
    };

    const containerLabelStyle = {
      position: "relative",
      alignSelf: "flex-end",
      top: "-25px",
      right: "5px",
      display: "flex",
      width: "100%",
      justifyContent: "end",
      height: 0,
    };
    const tagImageStyle = {
      backgroundColor: "#F6F6F6",
      borderRadius: "100%",
      objectFit: "cover",
      width: "60px",
      height: "60px",
      float: "left",
      margin: "0 3px",
    };

    const bodyStyle = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
    };
    const bodyStyleNi = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
      height: "100%",
    };

    const overflowStyle = {
      //overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0 8px",
    };
    const allergyContStyle = {
      //display: "block",
      padding: "0 5px",
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: 0,
    };

    const allergyIconStyle = {
      fill: this.props.item.colorAccent,
      textAlign: "center",
      width: "30px",
      margin: "3px 0",
    };

    const caloriesStyle = {
      marginBottom: "0",
      lineHeight: "1.1",
      color: this.props.item.colorAccent,
      fontSize: this.props.item.fontSizeCalories + "px",
      fontFamily: this.props.item.fontFamilySecondary,
    };

    const containerPricesStyle = {
      position: "absolute",
      bottom: "110px",
      right: "-45px",
      backgroundColor: this.props.item.colorPrice,
      maxWidth: "170px",
      border: "1px solid #fff",
      textAlign: "center",
      padding: "5px",
    };

    const pricesStyle = {
      marginBottom: 0,
      padding: "5px",
      textAlign: "center",
      fontFamily: this.props.item.fontFamilyPrice,
      color: this.props.item.colorPriceTx,
      fontSize: this.props.item.fontSizePrice + "px",
    };
    const containerfoodItemStyle = {
      height: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    };
    return (
      <>
        <div style={divStyle}>
          <div style={containerStyle}>
            {this.state.foodgroup[0] &&
              this.state.foodgroup
                .filter(function (value) {
                  return value.active !== false;
                })
                .slice(0, this.getmaxFoodItem())
                .map((fooditem, i) => {
                  return (
                    <div style={foodItemStyle} className="newshadow">
                      <div style={containerfoodItemStyle}>
                        {fooditem.config.isUsingImage === true &&
                          <ImageFoodGroup
                            ImageUploaded={this.props.item.headerImage}
                            foodItemTemplate={fooditem}
                            foodItemImage={fooditem.template.picto}
                            id_foodgroup={this.props.item.id}
                            color_accent={this.props.item.colorAccent}
                          />}
                        <div
                          style={
                            fooditem.config && fooditem.config.isUsingImage === false
                              ? bodyStyleNi
                              : bodyStyle
                          }
                        >
                          <div style={overflowStyle}>
                            <div className="containerCardFg">
                              {!fooditem.tags
                                ? null
                                : fooditem.tags
                                  .filter(function (value) {
                                    return value.tag.kind == "ORIGIN";
                                  })
                                  .map(function (tag, i) {
                                    return (
                                      <h4
                                        style={originStyle}
                                        className="itemCardFg"
                                      >
                                        <FontAwesomeIcon
                                          icon={faMapMarker}
                                          className="mr-2"
                                        />
                                        {tag.tag.name}
                                      </h4>
                                    );
                                  })}

                              {!fooditem.name ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleNIStyle
                                      : titleStyle
                                  }
                                >
                                  {fooditem.name}
                                </h3>
                              )}
                              {!fooditem.name_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleSecNIStyle
                                      : titleSecStyle
                                  }
                                >
                                  {fooditem.name_secondary}
                                </h3>
                              )}
                              {!fooditem.description ? null : (
                                <h3
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionNIStyle
                                      : descriptionStyle
                                  }
                                >
                                  {fooditem.description}
                                </h3>
                              )}
                              {!fooditem.description_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionSecNIStyle
                                      : descriptionSecStyle
                                  }
                                >
                                  {fooditem.description_secondary}
                                </h3>
                              )}
                              {!fooditem.calories ? null : (
                                <h4
                                  className="itemCardFg"
                                  style={caloriesStyle}
                                >
                                  Calories: {fooditem.calories} kcal
                                </h4>
                              )}
                              {!fooditem.tags ? null : (
                                <div
                                  className="itemCardFg"
                                  style={allergyContStyle}
                                >
                                  {fooditem.tags
                                    .filter(function (value) {
                                      return value.tag.kind == "ALLERGEN";
                                    })
                                    .map((item) => {
                                      return (
                                        <h3 style={allergyIconStyle}>
                                          {this.getIconSvg(item.tag.name)}
                                        </h3>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={containerLabelStyle}>
                        {!fooditem.tags
                          ? null
                          : fooditem.tags
                            .slice(0, 3)
                            .filter(function (value) {
                              return value.tag.kind == "LABEL";
                            })
                            .map(function (tag, i) {
                              return (
                                <Image
                                  className="newshadow"
                                  src={tag.tag.image}
                                  style={tagImageStyle}
                                />
                              );
                            })}
                      </div>
                      {fooditem.prices == null ? null : (
                        fooditem.prices[0].value == 0 || fooditem.prices[0].value == null ? null : (
                          <div
                            style={containerPricesStyle}
                            className="newshadow" >
                            <h3 style={pricesStyle}>
                              {fooditem.prices[0].value}
                              {fooditem.prices[0].currency === " " ||
                                fooditem.prices[0].currency === "" ||
                                fooditem.prices[0].currency === "EUR" ||
                                fooditem.prices[0].currency === "Eur" ||
                                fooditem.prices[0].currency === "eur" ||
                                fooditem.prices[0].currency === "Euro"
                                ? "€"
                                : this.capitalize(
                                  fooditem.prices[0].currency
                                )}
                            </h3>
                          </div>
                        )
                      )}
                    </div>
                  );
                })}
          </div>
        </div>
      </>
    );
  }
}

export default FoodGroup_Land;
